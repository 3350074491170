<template>
  <div class="GlobalTable">
    <!-- <div class="topaction">
      <el-button v-if="!outNo" size="small" type="primary" icon="el-icon-circle-plus-outline" @click="addMatchm">保存并新建</el-button>
      <el-button icon="" size="small" type="success" @click="exportExcel">确认出库</el-button>
      <el-button size="small" type="warning" icon="el-icon-printer" @click="exportExcel">打印出库单</el-button>
    </div> -->
    <div class="center_cont">
      <div class="cont_top">
        <div class="ct_tpd">
          单据详情
        </div>
        <div class="ct_conten">
          <div class="cf_jhj" style="margin-left: 15px;">
            <span>仓库:</span>
            <span>{{ detaleData.outWarehouseName }}</span>
          </div>
          <div class="cf_jhj">
            <span>出库单位:</span>
            <span>{{ detaleData.outSupplierName }}</span>
          </div>
          <div class="cf_jhj">
            <span>制单日期:</span>
            <span>{{ detaleData.outTime.split(" ")[0] }}</span>
          </div>
          <div class="cf_jhj">
            <span>制单人:</span>
            <span>{{ detaleData.outUserName }}</span>
          </div>
          <div class="cf_jhj" style="flex: 1;">
            <div style="width: 80px;"> <span>出库备注</span></div>
            <el-input :disabled="outNo" size="small" maxlength="50" v-model="remark" placeholder="请输入备注内容,最多50字"></el-input>
          </div>
        </div>
      </div>
      <div class="footbgf">
        <div class="cot_footer">
          <span>出库串号</span>
          <div style=" margin: 0 15px;">
            <el-input type="number" size="small" @clear="cliAction" :clearable="true" v-model="imei"
              placeholder="请输入出库串号"></el-input>
          </div>
          <el-button :disabled="outNo" type="primary" size="small" icon="el-icon-circle-plus-outline" @click="submitForm">查询</el-button>
          <span>共计出库{{ mimelistData.length }}台</span>
        </div>
        <div style="margin: 0 10px;padding-bottom: 20px;">
          <GlobalTable ref="GlobalTable" :isPagination="false" :maxHeight="400" v-loading="loading"
            :columns="tableColumns" :data="showData" @handleCurrentChange="handleCurrentChange">
            <el-table-column slot="orderCompanyName" label="下单门店商" align="center">
              <template slot-scope="{ row }">
                  <el-tooltip
                    v-if="row.orderCompanyName&&row.isOwnModel"
                    class="item"
                    effect="dark"
                    :content="row.orderCompanyName"
                    placement="top"
                  >
                    <div class="Remarks2">{{ row.orderCompanyName }}
                    </div>
                  </el-tooltip>
                  <span v-else-if="!row.orderCompanyName&&row.isOwnModel">--</span>
                  <div class="Remarks2" style="color:red" v-else-if="!row.isOwnModel">非您商户,无法查看</div>
                  
                </template>
            </el-table-column>
            <el-table-column slot="ackPrice" label="成交金额" align="center">
              <template slot-scope="{ row }">
                <span v-if="!row.isOwnModel">--</span>
                <span v-else>￥{{ row.ackPrice || '--' }}</span>
              </template>
            </el-table-column>   
          </GlobalTable>
        </div>
      </div>

    </div>
     <!-- 打印提示 -->
     <el-dialog title="打印提示" :visible.sync="commandDisableShow" :close-on-click-modal="false" width="350px"
      @closed="commandDialogClosed">
      <div class="command2">
        <div class="sure_tip" style="margin-bottom: 20px;">是否确认打印？</div>
        <div class="sure_tip">
          <span> 选择打印份数：</span>
          <el-input-number size="small" v-model="prnitnum" :min="1" :max="2"></el-input-number>
        </div>
      </div>
      <span slot="footer" class="dialog-footer prinsty">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button type="primary" @click.native="commandDisableSure" :loading="butLoading">确定</el-button>
      </span>
    </el-dialog>
    <!-- 动态口令弹出框 -->
    <el-dialog :title="commandTitle" :visible.sync="commandShow" :close-on-click-modal="false" width="500px"
      @closed="commandClosed">
      <div class="enable-tip">
        <div>
          说明：关闭后不再向该回收商进行撮合交易扣款，包括渠道扣款和繁星扣款。
        </div>
        <div class="tip-sure">关闭后不可重新开启，是否确认关闭？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="submit">确定</el-button>
      </span>
    </el-dialog>
    <!--弹框-->
    <el-dialog title="删除提示" :visible.sync="dialogDelVisible" :close-on-click-modal="false" width="380px"
      @closed="dialogDelVisible = false">
      <div class="del-title">
        <div class="sure_tip">确认要删除该数据吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogDelVisible = false">取消</el-button>
        <el-button type="primary" @click.native="handleSuccessDel">确定</el-button>
      </span>
    </el-dialog>
    <!-- 打印出库单 -->
    <MimeoGraph ref="printView" :detaleDatax="copylistData" :coPies="prnitnum"></MimeoGraph>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { http } from "@/utils/request/newIndex";
export default {
  name: "Table",
  data() {
    return {
      outNo:'',
      remark: "",
      prnitnum:1,
      loading: false,
      seachDataList: [],
      commandShow: false,
      butLoading: false,
      commandTitle: "",
      command: "",
      matchId: "",
      imei: "",
      dialogVisible: false,
      takeEffectTimeIsEdit: false,
      rowId: "",
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      pageNum: 1,
      companySelectList: [],
      alreadyCompanyList: [],
      copylistData: {
        outWarehouseName: "",
        outSupplierName: "",
        outUserName: "",
        outNo: "",
        outTime: "",
        machines: [],
      },
      tableColumns: [
        { label: "旧机串号", prop: "imei" },
        { label: "旧机名称", prop: "phoneName" },
        { label: "旧机条码", prop: "barCode" },
        { label: "所属入库单位", prop: "intoSupplierName" },
        // { label: "下单门店商", prop: "orderCompanyName" },
        // { label: "成交金额", prop: "ackPrice"},
        { slotName: "orderCompanyName" },
        { slotName: "ackPrice" },
        // { label: "归属门店商户", prop: "revertCompanyName"},
        // { label: "归属商户成交金额", prop: "revertAckPrice"},
        { label: "扫码时间", prop: "scanTime" },
        // { slotName: "operation" }
      ],
      matchChannelName: "",
      merchantName: "",
      companyName: "",
      modelform: {
        matchChannelId: "",
        merchantId: "",
        companyId: "",
        takeEffectTime: "",
        command: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //禁止选择今天和今天之前的时间
        },
      },
      btnLoading: false,
      middleButLoading: false,
      middleRegionVos: [],
      middleRowId: "",
      middleRegion: {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      },
      dialogMiddleRegionTitle: "",
      dialogMiddleRegionVisible: false,
      dialogDelVisible: false,
      commandDisableShow: false,
      tableOperation: "",
      detaleData: null,
      mimelistData: [],
      actionData: [],
      showData: [],
    };
  },
  created() {
    this.outNo = this.$route.query.outNo
    console.log(this.$route.query.outNo);
    this.getInfon()
  },
  methods: {
    //查询
    submitForm() {
      if (this.imei) {
        this.actionData = []
        this.mimelistData.forEach(item => {
          if (item.imei == this.imei) {
            this.actionData.push(item)
          }
        })
        this.showData = this.actionData
      } else {
        this.showData = this.mimelistData
      }
    },
    cliAction() {
      console.log(this.imei);
      this.showData = this.mimelistData
    },
    exportExcel() {
      this.commandDisableShow=true
    },
    //详情
    getInfon() {
      http.outInfo({ outNo: this.$route.query.outNo ,merchantId:this.$route.query.merchantId}).then(res => {
        console.log(res);
        this.remark=res.data.outRemark
        this.detaleData = JSON.parse(JSON.stringify(res.data))
        this.mimelistData =  [...res.data.machines] //JSON.parse(JSON.stringify(res.data)).machines
        this.copylistData = this.detaleData
        this.showData = JSON.parse(JSON.stringify(res.data)).machines
      })
    },
    //新建出库
    addMatchm() {
      http.saveRemark({
        "outNo": this.$route.query.outNo,
        "remark": this.remark
      }).then(res => {
        this.$router.push({ path: "/outgoManagement/createWarehouse" })
      })

    },
    moneyInput(obj, prop, value) {
      value = "" + value.replace(/[^0-9]/g, "");
      if (!value) {
        this.$set(obj, prop, "");
        return;
      }
      value = parseInt(value);
      this.$set(obj, prop, value);
    },
    // 表格切换页面
    handleCurrentChange(val) {
      this.getMatchCompanyList();
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      }
      const baseRequest = {
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        endTime: this.SeachParams.endTime,
        matchChannelId: this.SeachParams.matchChannelId,
        startTime: this.SeachParams.startTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      // _api.matchChannelList(baseRequest).then((res) => {
      //   if (res.code === 1) {
      //     this.seachDataList = res.data.records;
      //     this.page.total = res.data.total;
      //     this.page.pageNum = res.data.current;
      //     this.loading = false;
      //   }
      // });
    },
    disableopen(row) {
      this.commandTitle = "关闭提示";
      this.commandShow = true;
      this.matchId = row.id;
    },
    // 关闭弹框关闭
    commandClosed() {
      this.commandShow = false;
      this.command = "";
    },
    // 确认关闭弹框
    submit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.butLoading = true;
      let params = {
        id: this.matchId,
        command: this.command,
      };
      // _api.closeMatchTransaction(params).then((res) => {
      //   this.$message.success(res.msg || "操作成功");
      //   this.butLoading = false;
      //   this.commandShow = false;
      //   this.command = "";
      //   this.handleCurrentChange();
      // });
    },
    //
    editBtn(row) {
      this.rowId = row.id;
      this.dialogVisible = true;
      this.takeEffectTimeIsEdit = row.state == "01";
      console.log(new Date(row.takeEffectTime).getTime());
      this.modelform = {
        matchChannelId: row.matchChannelId,
        id: row.id,
        merchantId: row.merchantId,
        companyId: row.companyId,
        takeEffectTime: new Date(row.takeEffectTime).getTime(),
      };
      this.matchChannelName = row.matchChannelName;
      this.merchantName = row.merchantName;
      this.companyName = row.companyName;
      this.middleRegionVos = JSON.parse(JSON.stringify(row.regionDtoList));
    },
    // 获取门店商家
    getMatchCompanyList() {
      // _api.matchCompanyList({ pageNum: 1, pageSize: 999 }).then((res) => {
      //   if (res.code === 1) {
      //     this.alreadyCompanyList = res.data;
      //   }
      // });
    },
    // 确认修改
    handleSuccess() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.middleRegionVos.length) {
            this.$message.error("请设置扣款条件");
            return;
          }
          this.commandDisableShow = true;
          // let params = {
          //   // matchChannelId: this.modelform.matchChannelId,
          //   id: this.modelform.id,
          //   channelTax: this.modelform.channelTax,
          //   btstarsTax: this.modelform.btstarsTax,
          //   takeEffectPrice: this.modelform.takeEffectPrice,
          //   takeEffectTime: this.modelform.takeEffectTime,
          //   command: this.modelform.command,
          // };
          // _api.editMatchTransaction(params).then((res) => {
          //   this.$message.success("成功");
          //   this.dialogVisible = false;
          //   this.handleCurrentChange();
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleDialogClosed() {
      this.$refs["form"].resetFields();
      this.dialogVisible = false;
    },
    // 添加扣款规则
    addRule() {
      this.tableOperation = "add";
      this.middleRowId = "";
      this.dialogMiddleRegionTitle = "添加规则-撮合扣款";
      this.dialogMiddleRegionVisible = true;
    },
    // 扣款条件表格操作
    handleBtn(row, type, index) {
      this.middleRowId = index;
      this.tableOperation = type;
      if (type == "edit") {
        this.dialogMiddleRegionTitle = "编辑规则-撮合扣款";
        this.middleRegion.lower = row.lower;
        this.middleRegion.upper = row.upper;
        this.middleRegion.btstarsTax = row.btstarsTax;
        this.middleRegion.channelTax = row.channelTax;
        this.dialogMiddleRegionVisible = true;
      } else {
        this.dialogDelVisible = true;
      }
    },
    middleRegionDialogClosed() {
      this.middleRegion = {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      };
      this.$refs["middleRegion"].resetFields();
    },
    // 添加规则-撮合扣款弹框确定
    middleRegHandleSuccess() {
      this.$refs["middleRegion"].validate((valid) => {
        if (valid) {
          let middleRegion = this.middleRegion;
          if (Number(middleRegion.lower) > Number(middleRegion.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(middleRegion.lower) == Number(middleRegion.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: middleRegion.lower,
            upper: middleRegion.upper,
          };
          if (this.middleRegionVos.length && this.tableOperation != "edit") {
            let numArr = [];
            this.middleRegionVos.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (middleRegion.upper != iMin && middleRegion.lower != iMAX) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (middleRegion.lower != iMAX && middleRegion.upper != iMin) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.btstarsTax = middleRegion.btstarsTax;
          params.channelTax = middleRegion.channelTax;
          if (this.middleRowId.toString()) {
            this.middleRegionVos.splice(this.middleRowId, 1, params);
          } else {
            this.middleRegionVos.push(params);
          }
          this.dialogMiddleRegionVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除弹框确认
    handleSuccessDel() {
      this.middleRegionVos.splice(this.middleRowId, 1);
      this.dialogDelVisible = false;
    },
    commandDialogClosed() {
      this.modelform.command = "";
    },
    // 打印弹框确定
    commandDisableSure() {
      this.commandDisableShow=false
      this.$refs.printView.disableopen()
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
/deep/.el-button {
  // border-radius: 10px;
  margin-right: 30px;
}

//去掉加减箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.topaction {
  padding: 0;
  // background: #E4ECFD;
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.footbgf {
  background: #fff;
  border-radius: 4px;
  padding-top: 1px;
}

.center_cont {
  // background: #fff;
  border-radius: 4px;

  .cot_footer {
    display: flex;
    align-items: center;
    margin: 14px 0 20px 20px;

    span:nth-of-type(1) {
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #666666;
    }

    span:nth-of-type(2) {
      font-size: 14px;
      color: #0981FF;
      margin-left: 40px;
    }
  }

  .cont_top {
    padding-top: 1px;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    padding-bottom: 1px;
    margin-bottom: 16px;
  }

  .ct_tpd {
    display: flex;
    align-items: center;
    margin: 20px 0 20px 15px;

    .cfhjs {
      font-size: 14px;
      font-family: FZLanTingHei-DB-GBK;
      font-weight: 400;
      color: #4C84FF;
      margin-left: 60px;

      span:nth-of-type(1) {
        font-weight: bold;
      }
    }

    .t_keft {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: FZLanTingHei-DB-GBK;
      font-weight: 400;
      color: #333333;

      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
      }
    }

  }

  .ct_conten {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 15px;
    margin-bottom: 20px;
    width: 95%;
    height: 60px;
    background: #f5f6fa;
    border-radius: 4px;

    .cf_jhj {
      display: flex;
      align-items: center;
      margin-right: 50px;
      // margin-bottom: 20px;

      span {
        font-size: 14px;
        color: #666666;
        padding-right: 8px;
      }

    }
  }
}

.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .add-form {
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-input-group__prepend {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-select {
      width: 100%;
    }
  }

  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }

    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }

  .del-title {
    color: #ff8080;
    font-size: 12px;

    
  }
 

  .command2 {
    // display: flex;
    // align-items: center;

    .sure_tip {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      color: #333333;
    }
   
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex1 {
    flex: 1;
  }

  .overflow-hiding {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1;
    /*! autoprefixer: off */
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
  }
}
.prinsty{
    /deep/.el-button {
      margin-right: 0;
    }
  }
</style>
