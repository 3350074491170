import request from './request'

export const http = {
    //在库旧机列表
    warehouseList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/innerList',
            data: params
        })
    },
    //D-导出在库旧机
    warehouseExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/innerExcel',
            data: params,
            responseType: 'blob'
        })
    },
    //串号跟踪
    imeiTrack(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/imei/track/list',
            params: params
        })
    },
    //导出串号跟踪
    imeiExcel(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/imei/track/excel',
            params: params,
            responseType: 'blob'
        })
    },
    //出库单据列表
    outwarehouseList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/outList',
            data: params
        })
    },
    //导出-出库单据
    outwarehouseExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/outExcel',
            data: params,
            responseType: 'blob'
        })
    },
    //生成出库单据
    outwarehousebuild(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/warehouse/out/build',
            data: params
        })
    },
    //通过串号查询库存旧机
    findByImei(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/warehouse/out/findByImei',
            params: params
        })
    },
    //出库单据详情
    outInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/erp/merchant/outInfo',
            params: params
        })
    },
    //保存取货方式
    saveDeliveryType(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/warehouse/out/saveDeliveryType',
            data: params
        })
    },
     //供应商下拉框(出库单位)
     pullDown(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/erp/merchant/out/pull/down',
            params: params
        })
    },
     //仓库下拉框
     warehouseDown(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/warehouse/pull/down',
            params: params
        })
    },
     //保存出库备注
     saveRemark(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/warehouse/out/saveRemark',
            data: params
        })
    },
     //导出-导出串号
     excelImei(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/excelImei',
            data: params,
            responseType: 'blob'
        })
    },
     //导出串号-所有
     excelImeiAll(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/excelImeiAll',
            data: params,
            responseType: 'blob'
        })
    },
}