<template>
  <div class="class-list">
      <Table />
  </div>
</template>

<script>
import Table from "./Table";
import _api from "@/utils/request";

export default {
  name: "class-list",
  components: {
    Table
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style>
</style>